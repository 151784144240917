<template>
  <div class="slider-component">
    <div class="row align-items-center">
      <div class="col-md-6 mb-3">
        <img class="w-100" :src="require(`@/assets/img/services-page${Imag}`)" />
      </div>
      <div class="col-md-6">
        <swiper class="swiper" :pagination="pagination" :space-between="20" :speed="1000">
          <swiper-slide v-for="(slide, index) in slides" :key="index">
            <h3>{{ slide.title1 }}</h3>
            <h5>{{ slide.title2 }}</h5>
            <p>{{ slide.description }}</p>
          </swiper-slide>
        </swiper>
        <!-- CTA Button Below the Slider -->
        <div class="cta-container"  style="margin-top: 40px">
          <a v-if="cta" :href="cta.link" class="btn-main">
            {{ cta.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        },
      }
    };
  },
  props: {
    Imag: String,
    slides: Array,
    cta: Object
  }
};
</script>

